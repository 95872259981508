import React from 'react';
import styled from 'styled-components';

const S = {
	Wrapper: styled.footer`
		width: 100%;
		padding: 50px;
		margin: auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		background-color: ${(props) => props.theme.palette.primary};
		color: ${(props) => props.theme.palette.white};
		line-height: 1.5rem;
		font-size: 0.8rem;
	`
};

const Footer = () => (
	<S.Wrapper>
		법인명(상호) : 주식회사 더프레샵 대표자(성명) : 송영내 사업자 등록번호 안내 : [171-87-01433] 통신판매업 신고 제2019-경북구미-0303호<br />
		전화 : 070-4334-0411 팩스 : 0504-172-8857 주소 : 39171 경상북도 구미시 산동면 첨단기업1로 17 구미전자정보기술원 혁신관 221호<br />
		개인정보보호책임자 : 송영내(thefreshop@thefreshop.co.kr)<br />
		Contact thefreshop@thefreshop.co.kr for more information.<br />
		Copyright © 2019 더프레샵. All rights reserved.
	</S.Wrapper>
);

export default Footer;
