import React from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

class Main extends React.Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	render() {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<img width={'100%'} src={'image/deliveryinfo.png'} alt="" />
			</div>
		);
	}
}

export default withCookies(Main);
