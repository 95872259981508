import React from 'react';
import styled from 'styled-components';
import { useScrollFadeIn } from '../../../hooks';

const S = {
	Contain: styled.div`
		width: 100%;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: ${(props) => props.theme.palette.background};
	`,
	Wrapper: styled.section`
		width: 100%;
		max-width: 768px;
		padding-top: 30px;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: ${(props) => props.theme.palette.background};
	`,
	TextWrapper: styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	`,
	Label: styled.p`
		display: inline-block;
		${(props) => props.theme.typography.label};
		color: ${(props) => props.theme.palette.primary};
		margin-bottom: 1rem;
	`,
	Title: styled.h2`
		${(props) => props.theme.typography.subtitle};
		color: ${(props) => props.theme.palette.black};
		margin-bottom: 1rem;
	`,
	SubTitle: styled.h2`
		font-size: 1.3rem;
		color: ${(props) => props.theme.palette.black};
		margin-bottom: 0.5rem;
	`,
	Description: styled.p`
		${(props) => props.theme.typography.description};
		color: ${(props) => props.theme.palette.black};
		margin-bottom: 1rem;
	`,
	List: styled.ul`
		width: 400vw;
		margin-bottom: 2rem;
	`,
	ListItem: styled.p`
		${(props) => props.theme.typography.description};
		padding: 1rem 1rem 1rem 0;
		border-bottom: 1vw solid ${(props) => props.theme.palette.lightgray};
		span {
			color: ${(props) => props.theme.palette.secondary};
		}
	`,
	TextButton: styled.button`
		width: fit-content;
		${(props) => props.theme.typography.textbutton};
		color: ${(props) => props.theme.palette.secondary};
		cursor: pointer;
	`,
	Image: styled.div`
		width: 70%;
		height: 70vw;
		background: no-repeat center/contain url(image/hand_phone.png);
	`
};

const Banner = (props) => {
	const animatedImage = useScrollFadeIn();

	return (
		<S.Contain>
			<S.Wrapper>
				<S.TextWrapper>
					<S.Title>
						우리 아파트에서 <br /> 단지유를 만나보세요.
					</S.Title>
					<S.Description>
						우리 아파트에서 만나는 다양한 상품과 혜택 <br /> 지금 어플다운로드 후, 확인해보세요.
					</S.Description>
					<div style={{ display: 'flex', marginBottom: 10 }}>
						<button
							style={{
								backgroundColor: 'transparent',
								borderWidth: 0,
								padding: 0,
								marginRight: 10,
								border: 0
							}}
							onClick={() =>
								window.open('https://play.google.com/store/apps/details?id=com.min.danziyou')}
						>
							<img style={{ width: '25vw' }} src={'image/google_btn.png'} alt="" />
						</button>
						<button
							style={{ backgroundColor: 'transparent', borderWidth: 0, padding: 0, border: 0 }}
							onClick={() =>
								window.open('https://apps.apple.com/us/app/%EB%8B%A8%EC%A7%80%EC%9C%A0/id1538546945')}
						>
							<img style={{ width: '25vw' }} src={'image/appstore_btn.png'} alt="" />
						</button>
					</div>
				</S.TextWrapper>
				<S.Image {...animatedImage} />
			</S.Wrapper>
		</S.Contain>
	);
};

export default Banner;
