import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as ROUTES from '../../routes';
import '../../App.css';
import MainPage from './Main';
import AfterPage from './After';
import DeliveryinfoPage from './Deliveryinfo';
import Info from './Info';
import Admin from './Admin';
import Survey from './Survey';

export default class App extends React.Component {
	render() {
		return (
			<Router>
				<Route exact path={ROUTES.MAIN} render={(props) => <MainPage {...props} />} />
				<Route exact path={ROUTES.AFTER} render={(props) => <AfterPage {...props} />} />
				<Route exact path={ROUTES.DELIVERYINFO} render={(props) => <DeliveryinfoPage {...props} />} />
				<Route
					exact
					path={ROUTES.ADMIN}
					component={(props) => {
						window.open('http://thefreshop.iptime.org:9430/', '_self');
						return <Admin />;
					}}
				/>
				<Route path={ROUTES.INFO} render={(props) => <Info {...props} />} />
				<Route path={ROUTES.SURVEY} render={(props) => <Survey {...props} />} />
			</Router>
		);
	}
}
