import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import moment from "moment";
const config = {
  apiKey: "AIzaSyC2OkcQhiW-Bb6thIisJa8rmeUbHJx9pxM",
  authDomain: "danziyou.firebaseapp.com",
  projectId: "danziyou",
  storageBucket: "danziyou.appspot.com",
  messagingSenderId: "253281481979",
  appId: "1:253281481979:web:fe47acb23dfc94ecba2c5e",
  measurementId: "G-QQQXJCRB86",
};
app.initializeApp(config);

export const DB = (data) => {
  return new Promise((resolve) => {
    app
      .firestore()
      .collection("Survey")
      .add(data)
      .then(() => {
        resolve();
      });
  });
};

export const Supply = (data) => {
  return new Promise((resolve) => {
    app
      .firestore()
      .collection("Application")
      .get()
      .then((snapshot) => {
        app
          .firestore()
          .collection("Application")
          .add({
            type: "CompanyAdd",
            Check: false,
            Answer: "",
            data: JSON.stringify(data.data),
            col: JSON.stringify(data.col),
            time: moment().format("YYYY-MM-DD HH:mm:ss"),
          })
          .then(() => {
            resolve();
          });
      });
  });
};
