import todoAction from '../action/index';
const { AUTH_LOGIN, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE, AUTH_LOGOUT } = todoAction.authentication;

const initialState = {
	authuser: false,
	username: ''
};

const authentication = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_LOGIN:
			return {
				...state
			};
		case AUTH_LOGIN_SUCCESS:
			//console.log('AUTH_LOGIN_SUCCESS : ' + action.username);
			return Object.assign({}, state, {
				authuser: true,
				username: action.username
			});
		case AUTH_LOGIN_FAILURE:
			return Object.assign({}, state, {
				authuser: false
			});
		case AUTH_LOGOUT:
			return Object.assign({}, state, {
				authuser: false
			});
		default:
			return state;
	}
};

export default authentication;
