import React from 'react';

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

import { Header, Banner, Story, Supply, Footer } from './components';

class Main extends React.Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	render() {
		return (
			<div ref={this.targetRef}>
				<Header />
				<Banner />
				<Story />
				<Supply />
				<Footer />
			</div>
		);
	}
}

export default withCookies(Main);
