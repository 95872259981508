import React, { useState } from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { useScrollFadeIn } from "../../../hooks";
import { Supply } from "../../../firebase";

const S = {
  Contain: styled.section`
    width: 100%;
    background-color: ${(props) => props.theme.palette.primary}11;
  `,
  Wrapper: styled.section`
    width: 100%;
    max-width: 1023px;
    margin: auto;
    display: flex;
    padding: 120px;
    flex-direction: row;
    justify-content: flex-end;
  `,
  Info: styled.div`
    flex: 1;
  `,
  Logo: styled.span`
    ${(props) => props.theme.typography.label};
    color: ${(props) => props.theme.palette.darkgray};
    display: inline-block;
    font-weight: 900;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  `,
  Text: styled.p`
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;
    }
  `,
  Image: styled.div`
    width: 580px;
    height: 580px;
    background: no-repeat center/cover url(${(props) => props.image});
  `,
  TextWrapper: styled.div`
    text-align: right;
    box-sizing: border-box;
    width: 60%;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  Label: styled.p`
    display: inline-block;
    ${(props) => props.theme.typography.label};
    color: ${(props) => props.theme.palette.primary};
    margin-bottom: 1rem;
  `,
  Line: styled.div`
    margin-bottom: 3rem;
    height: 2px;
    width: 7%;
    background-color: ${(props) => props.theme.palette.primary};
  `,
  Title: styled.h2`
    ${(props) => props.theme.typography.subtitle};
    color: ${(props) => props.theme.palette.black};
    margin-bottom: 1rem;
  `,
  Description: styled.p`
    ${(props) => props.theme.typography.description};
    color: ${(props) => props.theme.palette.gray};
    margin-bottom: 2rem;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    input {
      margin-bottom: 1rem;
    }
    button {
      align-self: flex-end;
      width: 30%;
    }
  `,
  Button: styled.button`
    margin-top: 2rem;
    padding: 1rem 2rem;
    border: 0;
    outline: none;

    cursor: pointer;
    ${(props) => props.theme.typography.button};
    background-color: ${(props) => props.theme.palette.black};
    width: fit-content;
    color: ${(props) => props.theme.palette.white};
  `,
  SubItemImage: styled.div`
    width: 20px;
    height: 30px;
    margin-right: 10px;
    background: no-repeat center/contain url(${(props) => props.image});
  `,
};

const SupplyPage = (props) => {
  const [isTitle, setIsTitle] = useState("");
  const [isMemo, setIsMemo] = useState("");
  const [isPhone, setIsPhone] = useState("");
  const [isAddress, setIsAddress] = useState("");

  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.2),
    2: useScrollFadeIn("up", 1, 0.3),
    3: useScrollFadeIn("up", 1, 0.4),
  };

  const onSubmit = (e) => {
    e.preventDefault();
    Supply({
      data: {
        Name: isTitle,
        PhoneNumber: isPhone,
        Address: isAddress,
        Memo: isMemo,
      },
      col: [
        {
          dataField: "Name",
          text: "이름",
          format: "Text",
        },
        {
          dataField: "PhoneNumber",
          text: "휴대폰 번호",
          format: "Text",
        },
        {
          dataField: "Address",
          text: "주소",
          format: "Text",
        },
        {
          dataField: "Memo",
          text: "메모",
          format: "Text",
        },
      ],
    }).then(() => {
      alert("문의 신청이 완료되었습니다.");
      setIsMemo("");
      setIsTitle("");
      setIsPhone("");
      setIsAddress("");
    });
  };

  return (
    <S.Contain>
      <S.Wrapper>
        <S.Info>
          <S.Label {...animatedItem[0]}>INQUIRY</S.Label>
          <S.Line />
          <S.Title {...animatedItem[1]}>단지유 입점문의</S.Title>
          <S.Text {...animatedItem[2]}>
            <div>
              <S.SubItemImage image={"image/icon_01.jpg"} />
              <span>010-2392-8857</span>
            </div>
            <div>
              <S.SubItemImage image={"image/icon_02.jpg"} />
              <span>danziu@naver.com</span>
            </div>{" "}
            <div>
              <S.SubItemImage image={"image/icon_03.jpg"} />
              <span>평일 09:00 - 18:00 (주말, 공휴일 제외)</span>
            </div>
          </S.Text>
        </S.Info>
        <S.TextWrapper>
          <S.Form {...animatedItem[3]} onSubmit={onSubmit}>
            <Form.Control
              placeholder="업체명"
              value={isTitle}
              onChange={(e) => {
                setIsTitle(e.target.value);
              }}
            />

            <Form.Control
              type="phone"
              placeholder="연락 가능한 전화번호"
              value={isPhone}
              onChange={(e) => {
                setIsPhone(e.target.value);
              }}
            />

            <Form.Control
              placeholder="업체 주소"
              value={isAddress}
              onChange={(e) => {
                setIsAddress(e.target.value);
              }}
            />

            <Form.Control
              as="textarea"
              placeholder="내용"
              value={isMemo}
              onChange={(e) => {
                setIsMemo(e.target.value);
              }}
            />

            <S.Button type="submit">문의하기</S.Button>
          </S.Form>
        </S.TextWrapper>
      </S.Wrapper>
    </S.Contain>
  );
};

export default SupplyPage;
