import authentication from './authentication';
import globaldata from './globaldata';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
	authentication,
	globaldata
});

export default rootReducer;
