import React from 'react';

export default class Admin extends React.Component {
	render() {
		return (
			<a href={'http://thefreshop.iptime.org:9430/'} target={'_blank'}>
				관리자 사이트 바로가기
			</a>
		);
	}
}
