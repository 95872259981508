import todoAction from '../action/index';
const { GLOBAL_PRODUCT, GLOBAL_CATEGORY } = todoAction.globaldata;

const initialState = {
	productData: {},
	categoryData: [],
	isproduct: false,
	iscategory: false
};

const globaldata = (state = initialState, action) => {
	switch (action.type) {
		case GLOBAL_PRODUCT:
			return {
				...state,
				productData: action.productData,
				isproduct: true
			};
		case GLOBAL_CATEGORY:
			return {
				...state,
				categoryData: action.categoryData,
				iscategory: true
			};
		default:
			return state;
	}
};

export default globaldata;
