import React from 'react';

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { Button, Modal, Spinner, InputGroup, Form } from 'react-bootstrap';
import { Formik } from 'formik';

import { DB } from '../../firebase';
import { Header, Banner, Story, Supply, Footer } from './components';

class Main extends React.Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};
	constructor(props) {
		super(props);
		this.state = {
			sex: [ '남', '여' ],
			age: [ '50대 이상', '40대', '30대', '20대' ],
			question1: [ '있다', '없다' ],
			question2: [ '저렴한 가격', '신선도', '편리한 결제', '빠른배송', '기타 (직접입력)' ],
			question3: [ '신선도를 확인할 수 없어서', '가격이 비싸서', '제품의 교환 및 반품이 불편해서', '배송중 식품이 변질되거나 상할까봐', '기타 (직접입력)' ],
			question4: [ '그렇다', '아니다' ],
			question5: [ '그렇다', '아니다' ],
			question6: [ '그렇다', '아니다' ],
			question7: [ '그렇다', '아니다' ],
			question8: [ '그렇다', '아니다' ],
			question9: [ '그렇다', '아니다' ],
			InitData: {
				age: null,
				sex: null,
				question1: null,
				question2: null,
				question2memo: '',
				question3: null,
				question3memo: '',
				question4: null,
				question5: null,
				question6: null,
				question7: null,
				question8: null,
				question9: null,
				memo1: '',
				memo2: ''
			}
		};
	}

	Submint = (value) => {
		let dat = value;
		if (dat.sex !== null) dat.sex = this.state.sex[value.sex];
		if (dat.age !== null) dat.age = this.state.age[value.age];
		if (dat.question1 !== null) dat.question1 = this.state.question1[value.question1];
		if (dat.question2 !== null) dat.question2 = this.state.question2[value.question2];
		if (dat.question3 !== null) dat.question3 = this.state.question3[value.question3];
		if (dat.question4 !== null) dat.question4 = this.state.question4[value.question4];
		if (dat.question5 !== null) dat.question5 = this.state.question5[value.question5];
		if (dat.question6 !== null) dat.question6 = this.state.question6[value.question6];
		if (dat.question7 !== null) dat.question7 = this.state.question7[value.question7];
		if (dat.question8 !== null) dat.question8 = this.state.question8[value.question8];
		if (dat.question9 !== null) dat.question9 = this.state.question9[value.question9];

		DB(dat).then(() => {
			this.setState({
				InitData: {
					age: null,
					sex: null,
					question1: null,
					question2: null,
					question2memo: '',
					question3: null,
					question3memo: '',
					question4: null,
					question5: null,
					question6: null,
					question7: null,
					question8: null,
					question9: null,
					memo1: '',
					memo2: ''
				}
			});
			alert('제출되었습니다.\n감사합니다');
		});
	};

	render() {
		return (
			<div ref={this.targetRef}>
				<Header />
				<div style={{ margin: 20 }}>
					<Formik onSubmit={this.Submint} initialValues={this.state.InitData} enableReinitialize>
						{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
							<Form onSubmit={handleSubmit}>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										1. 귀하의 성별은?
									</Form.Label>
									<div
										style={{
											margin: 0,
											padding: 10
										}}
									>
										{this.state.sex.map((value, index) => {
											return (
												<Form.Check
													checked={values.sex === index ? true : false}
													style={{ marginBottom: 10 }}
													type={'radio'}
													label={`${index + 1}) ${value}`}
													id={`${index + 1}) ${value}`}
													onChange={() => {
														this.setState({
															InitData: {
																...this.state.InitData,
																sex: index
															}
														});
													}}
												/>
											);
										})}
									</div>
								</Form.Group>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										2. 귀하의 연령은?
									</Form.Label>
									<div
										style={{
											margin: 0,
											padding: 10
										}}
									>
										{this.state.age.map((value, index) => {
											return (
												<Form.Check
													checked={values.age === index ? true : false}
													style={{ marginBottom: 20 }}
													type={'radio'}
													label={`${index + 1}) ${value}`}
													id={`${index + 1}) ${value}`}
													onChange={() => {
														this.setState({
															InitData: {
																...this.state.InitData,
																age: index
															}
														});
													}}
												/>
											);
										})}
									</div>
								</Form.Group>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										3. 온라인에서 식품을 구매한적이 있나요?
									</Form.Label>
									<div
										style={{
											margin: 0,
											padding: 10
										}}
									>
										{this.state.question1.map((value, index) => {
											return (
												<Form.Check
													checked={values.question1 === index ? true : false}
													style={{ marginBottom: 20 }}
													type={'radio'}
													label={`${index + 1}) ${value}`}
													id={`${index + 1}) ${value}`}
													onChange={() => {
														this.setState({
															InitData: {
																...this.state.InitData,
																question1: index
															}
														});
													}}
												/>
											);
										})}
									</div>
								</Form.Group>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										4. 온라인으로 식품 구매시 가장 고려하는 부분은 무엇인가요?
									</Form.Label>
									<div
										style={{
											margin: 0,
											padding: 10
										}}
									>
										{this.state.question2.map((value, index) => {
											return (
												<Form.Check
													checked={values.question2 === index ? true : false}
													style={{ marginBottom: 20 }}
													type={'radio'}
													label={`${index + 1}) ${value}`}
													id={`${index + 1}) ${value}`}
													onChange={() => {
														this.setState({
															InitData: {
																...this.state.InitData,
																question2: index
															}
														});
													}}
												/>
											);
										})}
									</div>
									{values.question2 === 4 ? (
										<Form.Control
											type="text"
											name={'question2memo'}
											value={values.question2memo}
											onChange={handleChange}
										/>
									) : null}
								</Form.Group>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										5. 온라인에서 식품을 구매하지 않는이유는 무엇인가요?
									</Form.Label>
									<div
										style={{
											margin: 0,
											padding: 10
										}}
									>
										{this.state.question3.map((value, index) => {
											return (
												<Form.Check
													checked={values.question3 === index ? true : false}
													style={{ marginBottom: 20 }}
													type={'radio'}
													label={`${index + 1}) ${value}`}
													id={`${index + 1}) ${value}`}
													onChange={() => {
														this.setState({
															InitData: {
																...this.state.InitData,
																question3: index
															}
														});
													}}
												/>
											);
										})}
									</div>
									{values.question3 === 4 ? (
										<Form.Control
											type="text"
											name={'question3memo'}
											value={values.question3memo}
											onChange={handleChange}
										/>
									) : null}
								</Form.Group>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										6. 단지유마켓 앱 서비스가 안정적이며 접속, 속도 등에 만족하시나요?
									</Form.Label>
									<div
										style={{
											margin: 0,
											padding: 10
										}}
									>
										{this.state.question4.map((value, index) => {
											return (
												<Form.Check
													checked={values.question4 === index ? true : false}
													style={{ marginBottom: 20 }}
													type={'radio'}
													label={`${index + 1}) ${value}`}
													id={`${index + 1}) ${value}`}
													onChange={() => {
														this.setState({
															InitData: {
																...this.state.InitData,
																question4: index
															}
														});
													}}
												/>
											);
										})}
									</div>
								</Form.Group>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										7. 단지유마켓 앱의 색상, 이미지 등 디자인 화면 구성에 대해 만족하시나요?
									</Form.Label>
									<div
										style={{
											margin: 0,
											padding: 10
										}}
									>
										{this.state.question5.map((value, index) => {
											return (
												<Form.Check
													checked={values.question5 === index ? true : false}
													style={{ marginBottom: 20 }}
													type={'radio'}
													label={`${index + 1}) ${value}`}
													id={`${index + 1}) ${value}`}
													onChange={() => {
														this.setState({
															InitData: {
																...this.state.InitData,
																question5: index
															}
														});
													}}
												/>
											);
										})}
									</div>
								</Form.Group>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										8. 로그인이 쉽고 편리한가요? (예: 네이버 아이디로 로그인, 카카오 아이디로 로그인)
									</Form.Label>
									<div
										style={{
											margin: 0,
											padding: 10
										}}
									>
										{this.state.question6.map((value, index) => {
											return (
												<Form.Check
													checked={values.question6 === index ? true : false}
													style={{ marginBottom: 20 }}
													type={'radio'}
													label={`${index + 1}) ${value}`}
													id={`${index + 1}) ${value}`}
													onChange={() => {
														this.setState({
															InitData: {
																...this.state.InitData,
																question6: index
															}
														});
													}}
												/>
											);
										})}
									</div>
								</Form.Group>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										9. 결제가 쉽고 편리한가요?
									</Form.Label>
									<div
										style={{
											margin: 0,
											padding: 10
										}}
									>
										{this.state.question7.map((value, index) => {
											return (
												<Form.Check
													checked={values.question7 === index ? true : false}
													style={{ marginBottom: 20 }}
													type={'radio'}
													label={`${index + 1}) ${value}`}
													id={`${index + 1}) ${value}`}
													onChange={() => {
														this.setState({
															InitData: {
																...this.state.InitData,
																question7: index
															}
														});
													}}
												/>
											);
										})}
									</div>
								</Form.Group>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										10. 원하는 메뉴와 정보를 쉽게 찾을 수 있나요?
									</Form.Label>
									<div
										style={{
											margin: 0,
											padding: 10
										}}
									>
										{this.state.question8.map((value, index) => {
											return (
												<Form.Check
													checked={values.question8 === index ? true : false}
													style={{ marginBottom: 20 }}
													type={'radio'}
													label={`${index + 1}) ${value}`}
													id={`${index + 1}) ${value}`}
													onChange={() => {
														this.setState({
															InitData: {
																...this.state.InitData,
																question8: index
															}
														});
													}}
												/>
											);
										})}
									</div>
								</Form.Group>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										11. 상품에 대한 정보가 충분히 설명이 되었나요?
									</Form.Label>
									<div
										style={{
											margin: 0,
											padding: 10
										}}
									>
										{this.state.question9.map((value, index) => {
											return (
												<Form.Check
													checked={values.question9 === index ? true : false}
													style={{ marginBottom: 20 }}
													type={'radio'}
													label={`${index + 1}) ${value}`}
													id={`${index + 1}) ${value}`}
													onChange={() => {
														this.setState({
															InitData: {
																...this.state.InitData,
																question9: index
															}
														});
													}}
												/>
											);
										})}
									</div>
								</Form.Group>

								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										12. 추가되었으면 하는 상품 관련 정보가 있으신가요? (자유롭게 기술)
									</Form.Label>
									<InputGroup.Prepend
										style={{
											margin: 0,
											padding: 10
										}}
									>
										<Form.Control
											name={'memo1'}
											value={values.memo1}
											as="textarea"
											rows={3}
											onChange={handleChange}
										/>
									</InputGroup.Prepend>
								</Form.Group>
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											backgroundColor: '#e0e0e0',
											padding: 10,
											width: '100%'
										}}
									>
										13. 앱을 사용하면서 불편사항이나 개선 요구사항이 있으시면 의견 부탁드립니다. (자유롭게 기술)
									</Form.Label>
									<InputGroup.Prepend
										style={{
											margin: 0,
											padding: 10
										}}
									>
										<Form.Control
											name={'memo2'}
											value={values.memo2}
											as="textarea"
											rows={3}
											onChange={handleChange}
										/>
									</InputGroup.Prepend>
								</Form.Group>

								<Button type="submit" variant="primary">
									제출
								</Button>
							</Form>
						)}
					</Formik>
				</div>

				<Footer />
			</div>
		);
	}
}

export default withCookies(Main);
