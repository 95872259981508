import React, { useEffect, useState, useCallback } from 'react';

import styled from 'styled-components';

const S = {
	Wrapper: styled.div`
		width: 100%;
		padding-left: 100px;
		position: sticky;
		top: 0;
		z-index: 1000;
		transition: all 0.2s ease-in-out;
		background-color: ${({ isScroll, theme }) => (isScroll ? theme.palette.white : theme.palette.background)};
		box-shadow: ${(props) => (props.isScroll ? '0 0 16px 8px rgba(0, 0, 0, 0.03)' : 'none')};
	`,
	Header: styled.header`
		width: 100%;
		max-width: 1023px;
		margin: auto;
		transition: all 0.2s ease-in-out;
		height: ${(props) => (props.isScroll ? '70px' : '100px')};
		display: flex;
		flex-direction: row;
		align-items: center;
	`,

	Image: styled.div`
		width: 100px;
		height: 40px;
		background: no-repeat center/contain url(${(props) => props.image});
	`
};

const Header = (props) => {
	const [ isScroll, setIsScroll ] = useState(true);

	const handleScroll = useCallback(() => {
		if (window.pageYOffset > 0) {
			setIsScroll(true);
		}
		if (window.pageYOffset === 0) {
			setIsScroll(false);
		}
	}, []);

	useEffect(
		() => {
			window.addEventListener('mousewheel', handleScroll);
			return () => {
				window.removeEventListener('mousewheel', handleScroll);
			};
		},
		[ handleScroll ]
	);

	return (
		<S.Wrapper isScroll={isScroll}>
			<S.Header isScroll={isScroll}>
				<S.Image image={'image/logo.png'} />
			</S.Header>
		</S.Wrapper>
	);
};

export default Header;
