// action type
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export function login() {
	return {
		type: AUTH_LOGIN
	};
}

export function logout() {
	return {
		type: AUTH_LOGOUT
	};
}

export function loginSuccess(username) {
	return {
		type: AUTH_LOGIN_SUCCESS,
		username
	};
}

export function loginFailure() {
	return {
		type: AUTH_LOGIN_FAILURE
	};
}
