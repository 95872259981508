import React from 'react';
import styled from 'styled-components';
import { useScrollFadeIn } from '../../../hooks';

const S = {
	Contain: styled.div`
		width: 100%;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: ${(props) => props.theme.palette.white};
	`,
	Wrapper: styled.div`
		width: 100%;
		max-width: 768px;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	Label: styled.p`
		display: inline-block;
		${(props) => props.theme.typography.label};
		color: ${(props) => props.theme.palette.primary};
		margin-bottom: 1rem;
	`,
	Title: styled.h2`
		${(props) => props.theme.typography.subtitle};
		color: ${(props) => props.theme.palette.black};
		text-align: center;
		margin-bottom: 4rem;
	`,
	Description: styled.p`
		${(props) => props.theme.typography.description};
		color: ${(props) => props.theme.palette.gray};
		margin-bottom: 4rem;
	`,
	List: styled.ul`
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	`,
	ListItem: styled.li`
		width: 100%;
		display: flex;
		flex-direction: column;
		background-color: ${(props) => props.theme.palette.white};
		margin-bottom: 4rem;
	`,
	ItemImage: styled.div`
		width: 90%;
		height: 50vw;
		background: no-repeat center/contain url(${(props) => props.image});
		background-color: ${(props) => props.theme.palette.white};
	`,
	TextContainer: styled.div`padding: 2rem;`,
	ItemTitle: styled.h2`
		${(props) => props.theme.typography.subtitle};
		color: ${(props) => props.theme.palette.black};
		margin-bottom: 0.75rem;
	`,
	ItemLabel: styled.p`
		${(props) => props.theme.typography.caption};
		color: ${(props) => props.theme.palette.gray};
		font-weight: bold;
		margin-bottom: 1.5rem;
	`,
	ItemDesciption: styled.p`
		${(props) => props.theme.typography.description};
		margin-bottom: 1.5rem;
	`,
	TextButton: styled.button`
		width: fit-content;
		padding: 10px;
		${(props) => props.theme.typography.textbutton};
		color: ${(props) => props.theme.palette.secondary};
		cursor: pointer;
	`,
	Line: styled.div`
		margin-bottom: 1rem;
		height: 2px;
		width: 15%;
		background-color: ${(props) => props.theme.palette.primary};
	`,
	SubItemImage: styled.div`
		margin-left: 1vw;
		width: 25vw;
		height: 50px;
		background: no-repeat center/contain url(${(props) => props.image});
		background-color: ${(props) => props.theme.palette.white};
	`
};

const WORKS_ITEMS = [
	{
		image: 'image/img_01.png',
		title: `요즘 뭐먹지?\n 단지유가 추천합니다.`,
		label: '#산지직송 #신선배달 #인기배달 #제철음식',
		description:
			'지금 먹으면 딱! 좋은 상품을 지역 농가 및 생산업체와 직거래 유통망을 구축하여 아파트 단지별 공공구매를 진행하고 단지유 자체 배달 시스템을 통해 품질 좋은 상품을 제공합니다.'
	},
	{
		image: 'image/img_02.png',
		title: '지금 딱! 필요한\n 상품, 서비스 제공',
		label: '#엔지니어 #출장/방문 #서비스',
		description: '우리 아파트에 필요한 각종 업체정보를 제공하고 고객 서비스와 가격 만족도를 높였습니다.',
		SubImage: 'image/point_text.png'
	}
];

const Story = () => {
	const animatedItem = {
		0: useScrollFadeIn('up', 1, 0),
		1: useScrollFadeIn('up', 1, 0.2),
		2: useScrollFadeIn('up', 1, 0.3),
		3: useScrollFadeIn('up', 1, 0.4)
	};

	return (
		<S.Contain>
			<S.Wrapper>
				<S.List>
					{WORKS_ITEMS.map((item, index) => {
						if (index === 0) {
							return (
								<S.ListItem key={item.title} {...animatedItem[index]}>
									<S.TextContainer>
										<S.Label>OUR STORY</S.Label>
										<S.Line />
										<S.ItemTitle>
											{item.title.split('\n').map((line) => {
												return <div>{line}</div>;
											})}
										</S.ItemTitle>
										<S.ItemDesciption>{item.description}</S.ItemDesciption>
										<S.ItemLabel style={{ marginBottom: 5 }}>{item.label}</S.ItemLabel>
									</S.TextContainer>
									<S.ItemImage image={item.image} index={'0.5rem 0 0 0.5rem'} />
								</S.ListItem>
							);
						} else {
							return (
								<S.ListItem key={item.title} {...animatedItem[index]}>
									<S.TextContainer>
										{item.SubImage !== undefined ? <S.SubItemImage image={item.SubImage} /> : null}
										<S.ItemTitle>
											{item.title.split('\n').map((line) => {
												return <div>{line}</div>;
											})}
										</S.ItemTitle>
										<S.ItemDesciption>{item.description}</S.ItemDesciption>
										<S.ItemLabel style={{ marginBottom: 5 }}>{item.label}</S.ItemLabel>
									</S.TextContainer>
									<S.ItemImage image={item.image} index={'0.5rem 0 0 0.5rem'} />
								</S.ListItem>
							);
						}
					})}
				</S.List>

				{/*<Button fill="outline" onClick={()=>setTest(Test.concat([WORKS_ITEMS]))}>More Works</Button>*/}
			</S.Wrapper>
		</S.Contain>
	);
};

export default Story;
