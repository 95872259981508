import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ThemeProvider } from 'styled-components';
import PcPage from './Main/PC';
import TabletPage from './Main/Tablet';
import MobilePage from './Main/Mobile';
import { GlobalStyle } from './styles';
import { theme, theme2 } from './styles/theme';
export default function App() {
	const isPc = useMediaQuery({
		query: '(min-width:1024px)'
	});
	const isTablet = useMediaQuery({
		query: '(min-width:768px) and (max-width:1023px)'
	});
	const isMobile = useMediaQuery({
		query: '(max-width:767px)'
	});
	return (
		<ThemeProvider theme={isPc ? theme : theme2}>
			<GlobalStyle />
			{isPc && <PcPage />}
			{isTablet && <TabletPage />}
			{isMobile && <MobilePage />}
		</ThemeProvider>
	);
}
