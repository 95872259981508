import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './redux/store/index';

import { Provider } from 'react-redux';
import rootReducer from './redux/reducer';
import { CookiesProvider } from 'react-cookie';

import './index.css';
import App from './App.tsx';

const store = configureStore(rootReducer);

const render = () => {
	ReactDOM.render(
		<Provider store={store}>
			<CookiesProvider>
				<App />
			</CookiesProvider>
		</Provider>,
		document.getElementById('root')
	);
};

store.subscribe(render);
render();
