export const GLOBAL_PRODUCT = 'GLOBAL_PRODUCT';
export const GLOBAL_CATEGORY = 'GLOBAL_CATEGORY';

export function setProduct(productData) {
	return {
		type: GLOBAL_PRODUCT,
		productData
	};
}

export function setCategory(categoryData) {
	return {
		type: GLOBAL_CATEGORY,
		categoryData
	};
}
